import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import './ReportErrorForm.css';

function ReportErrorForm() {
    const [email, setEmail] = useState('');
    const [errorType, setErrorType] = useState('');
    const [message, setMessage] = useState('');
    const [gameName, setGameName] = useState('');
    const [captchaInput, setCaptchaInput] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const navigate = useNavigate(); // Para la redirección

    // Estados para el CAPTCHA
    const [num1, setNum1] = useState(0);
    const [num2, setNum2] = useState(0);
    const [captchaAnswer, setCaptchaAnswer] = useState(0);

    // Obtén el gameId de los parámetros de la URL
    const { gameId } = useParams();

    useEffect(() => {
        // Genera los números y la respuesta del CAPTCHA solo una vez al montar el componente
        const randomNum1 = Math.floor(Math.random() * 10) + 1;
        const randomNum2 = Math.floor(Math.random() * 10) + 1;
        setNum1(randomNum1);
        setNum2(randomNum2);
        setCaptchaAnswer(randomNum1 + randomNum2);
    }, []);

    useEffect(() => {
        // Realiza una solicitud para obtener el nombre del juego basado en gameId
        fetch(`${process.env.REACT_APP_API_BASE_URL}/juego/${gameId}`)
            .then(response => response.json())
            .then(data => {
                // Asegúrate de que el nombre del juego esté en la estructura correcta de la respuesta
                if (data.juego && data.juego.length > 0) {
                    setGameName(data.juego[0].nombre_juego);
                } else {
                    console.log('Juego no encontrado');
                }
            })
            .catch(error => console.error('Error:', error));
    }, [gameId]);

    const handleSubmit = (e) => {
        e.preventDefault();

        // Aquí el captchaAnswer ya no cambiará entre renderizaciones
        //console.log(`captchaInput: ${captchaInput}, captchaAnswer: ${captchaAnswer}`);
        if (parseInt(captchaInput) !== captchaAnswer) {
            alert('Respuesta incorrecta al CAPTCHA.');
            return;
        }

        // Validación simple de email
        const emailPattern = /\S+@\S+\.\S+/;
        if (!emailPattern.test(email)) {
            alert('Por favor, introduce un email válido.');
            return;
        }
    
        // Validación para asegurarse de que el mensaje no esté vacío
        if (!message.trim()) {
            alert('Por favor, escribe un mensaje.');
            return;
        }
    
       // Preparar los datos para enviar al servidor
    const formData = {
        juegoId: gameId,
        email: email,
        tipoError: errorType,
        mensaje: message
    };

    // Enviar los datos al servidor
    fetch(`${process.env.REACT_APP_API_BASE_URL}/reportar-error`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
    })
    .then(response => response.text())
    .then(data => {
        setIsSuccess(true); // Cambia a la vista de éxito
    })
    .catch(error => {
        console.error('Error:', error);
        alert('Error al enviar el reporte');
    });
};

    if (isSuccess) {
        // Vista de éxito
        return (
            <div className="success-message">
                <p>Hemos recibido tu reporte, muchas gracias. Revisaremos y actualizaremos la información de ser necesario.</p>
                <button onClick={() => navigate('/')}>Entendido</button>
            </div>
        );
    }
    
    // Establece el CAPTCHA como la cadena de la suma, por ejemplo "5 + 8"
    const captcha = `${num1} + ${num2}`;

    return (
        <div className='report-error-form'>
            <h2>Reportar Error para {gameName}</h2>
            <form onSubmit={handleSubmit}>
                <p>
                    <strong>Nombre del Juego:</strong> {gameName}
                </p>
                <label>
                    Tu email:
                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </label>
                <div className='radio-group'>
                    ¿Cuál es el error?:
                    <label>
                        <input type="radio" value="medidas" checked={errorType === 'medidas'} onChange={() => setErrorType('medidas')} />
                        Error en las medidas.
                    </label>
                    <label>
                        <input type="radio" value="protector" checked={errorType === 'protector'} onChange={() => setErrorType('protector')} />
                        El protector/funda es diferente.
                    </label>
                    <label>
                        <input type="radio" value="cantidad" checked={errorType === 'cantidad'} onChange={() => setErrorType('cantidad')} />
                        La cantidad de cartas es diferente.
                    </label>
                </div>
                <label>
                    Mensaje:
                    <textarea value={message} onChange={(e) => setMessage(e.target.value)} />
                </label>
                <label>
                    Captcha: ¿Cuánto es {captcha}?
                    <input type="text" value={captchaInput} onChange={(e) => setCaptchaInput(e.target.value)} />
                </label>
                <button className='boton-enviarForm' type="submit">Enviar</button>
            </form>
            <Link to="/" className="boton-volver">Volver</Link> {/* Botón para volver a la página principal */}
        </div>
    );
}

export default ReportErrorForm;
