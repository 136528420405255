import React from 'react';
import './Modal.css'; // Asegúrate de crear un archivo CSS para tu modal

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;
  
    return (
      <div className="modal-overlay" onClick={onClose}>
        <div onClick={e => e.stopPropagation()}>
          {children}
        </div>
      </div>
    );
  };
  
  export default Modal;
  
