import React from 'react';

const SearchResultPage = ({ searchResults, onViewGame }) => {

    // Verifica si hay resultados de búsqueda
    if (searchResults.length === 0) {
      // Si no hay resultados, muestra un mensaje
      return (
          <div className="no-results-message">
              <p>No encontramos el juego que buscas. Escríbenos <a href="mailto:contacto@poresoestoypobre.cl">aquí</a> para que lo agreguemos a la web :)</p>
          </div>
      );
  }

    return (
      <div>
        <div className="search-results-page">
      <h2>Resultados de Búsqueda</h2>
      <div className="search-grid-container">
          {searchResults.map(game => (
              <div key={game.juego_id} className="grid-item">
              <img src={game.caratula} alt={game.nombre_juego} className="game-image" />
              <div className="game-info">
                <h3>{game.nombre_juego}</h3>
                <p>{game.nombres_protectores}</p>
                <p>N° de Cartas: {game.num_cartas}</p>
                <button className="view-button" onClick={() => onViewGame(game.juego_id)}>Ver</button>
              </div>
            </div>
          ))}
        </div>
        </div>
      </div>
    );
  };
  
  export default SearchResultPage;