import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ReportErrorForm from './ReportErrorForm'; // Asegúrate de importar el componente
import { BrowserRouter, Routes, Route } from 'react-router-dom'; // Importa estos componentes de react-router-dom
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/reportar-error/:gameId" element={<ReportErrorForm />} />
        {/* Agrega más rutas aquí si es necesario */}
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app...
reportWebVitals();
