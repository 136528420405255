import logo from './logo.svg';
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass,faXmark } from '@fortawesome/free-solid-svg-icons';
import Modal from './Modal';
import SearchResultPage from './SearchResultPage';
import ReportErrorForm from './ReportErrorForm';
import Footer from './Footer';


function App() {
  const [searchTerm, setSearchTerm] = useState('');
  const [topGames, setTopGames] = useState([]);
  const [selectedGame, setSelectedGame] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchResultsPage, setShowSearchResultsPage] = useState(false);
  const [ultimosJuegos, setUltimosJuegos] = useState([]);
  const [totalJuegos, setTotalJuegos] = useState(0);



  const clearSearch = () => {
    setSearchTerm('');
    setSearchResults([]);
    setShowSearchResultsPage(false);
  };
  

/*   useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/top-juegos`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => setTopGames(data.juegos))
      .catch(error => console.error('Error fetching data:', error));
  }, []); */

  useEffect(() => {
    // Obtener los juegos más buscados
    fetch(`${process.env.REACT_APP_API_BASE_URL}/top-juegos`)
        .then(response => response.json())
        .then(data => setTopGames(data.juegos))
        .catch(error => console.error('Error fetching top games:', error));

    // Obtener los últimos juegos agregados
    fetch(`${process.env.REACT_APP_API_BASE_URL}/ultimos-juegos`)
        .then(response => response.json())
        .then(data => setUltimosJuegos(data.juegos))
        .catch(error => console.error('Error fetching last added games:', error));
}, []);

useEffect(() => {
  fetch(`${process.env.REACT_APP_API_BASE_URL}/total-juegos`)
      .then(response => response.json())
      .then(data => setTotalJuegos(data.total))
      .catch(error => console.error('Error:', error));
}, []);
  

/* const handleViewGame = (juegoId) => {
  fetch(`${process.env.REACT_APP_API_BASE_URL}/juego/${juegoId}`)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      setSelectedGame(data.juego);
      setIsModalOpen(true);
    })
    .catch(error => console.error('Error fetching game details:', error));
}; */

const handleViewGame = (juegoId) => {
  // Primero, incrementar el contador de búsquedas
  fetch(`${process.env.REACT_APP_API_BASE_URL}/incrementar-busqueda/${juegoId}`, {
      method: 'POST'
  })
  .then(response => {
      if (!response.ok) {
          throw new Error('Error al incrementar contador de búsquedas');
      }
      // Luego, obtener los detalles del juego
      return fetch(`${process.env.REACT_APP_API_BASE_URL}/juego/${juegoId}`);
  })
  .then(response => {
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }
      return response.json();
  })
  .then(data => {
      setSelectedGame(data.juego);
      setIsModalOpen(true);
  })
  .catch(error => console.error('Error:', error));
};


/* const handleSearch = () => {
  if (searchTerm) {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/buscar-juegos?q=${searchTerm}`)
      .then(response => response.json())
      .then(data => {
        setSearchResults(data.juegos);
        setShowSearchResultsPage(true);
      })
      .catch(error => console.error('Error fetching search results:', error));
  }
}; */

const handleSearch = () => {
  if (searchTerm) {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/buscar-juegos?q=${encodeURIComponent(searchTerm)}&buttonSearch=true`)
      .then(response => response.json())
      .then(data => {
        setSearchResults(data.juegos);
        setShowSearchResultsPage(true);
      })
      .catch(error => console.error('Error fetching search results:', error));
  }
};


const handleKeyPress = (e) => {
  if (e.key === 'Enter') {
    handleSearch();
  }
};



  const handleSearchChange = (e) => {
    const valor = e.target.value;
    setSearchTerm(valor);
  
    if (!valor || valor.length<=2) {
      // Si el campo de búsqueda está vacío, ocultar el componente SearchResultPage
      setShowSearchResultsPage(false);
      setSearchResults([]);
    } else if (valor.length > 2) {
      fetch(`${process.env.REACT_APP_API_BASE_URL}/buscar-juegos?q=${valor}`)
        .then(response => response.json())
        .then(data => setSearchResults(data.juegos))
        .catch(error => console.error('Error fetching search results:', error));
    } else {
      setSearchResults([]);
    }
  };
  

  return (
    <>
    <div className="App">
      <header className="App-header">
        <img className="logoHeader" src="/images/logo.webp" alt="Logo" />
      </header>
      <div className='contenedor-buscador'>
        <input 
          type="text" 
          placeholder="Buscar juego..." 
          value={searchTerm} 
          onChange={handleSearchChange} 
          onKeyPress={handleKeyPress}
        />
        {searchTerm && (
          <FontAwesomeIcon 
            icon={faXmark} 
            onClick={clearSearch} 
            className="clear-icon"
          />
        )}
        {!showSearchResultsPage && searchTerm && searchResults.length > 0 && (
          <ul className="search-results">
            {searchResults.map(game => (
              <li key={game.juego_id} onClick={() => handleViewGame(game.juego_id)}>
                <img src={game.caratula} alt={game.nombre_juego} className="search-image" />
                <span>{game.nombre_juego}</span>
              </li>
            ))}
          </ul>
        )}
      <button onClick={handleSearch}>
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </button>
      </div>
      {showSearchResultsPage && (
        <SearchResultPage searchResults={searchResults} onViewGame={handleViewGame}/>
      )}
      {/* Sección de Últimos Juegos Agregados */}
      <div>
                <h2>Últimos Juegos Agregados</h2>
                <div className="grid-container">
                    {ultimosJuegos.map(juego => (
                        <div key={juego.juego_id} className="grid-item">
                            <img src={juego.caratula} alt={juego.nombre_juego} className="game-image" />
                            <div className="game-info">
                            <p className='titulo-juego-home' ><h3>{juego.nombre_juego}</h3></p>
                                <p>{juego.nombres_protectores}</p>
                                <p>N° de Cartas: {juego.num_cartas}</p>
                                <button className="view-button" onClick={() => handleViewGame(juego.juego_id)}>Ver</button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
      <div>
        <h2>Los juegos más buscados</h2>
        <div className="grid-container">
          {topGames.map(game => (
              <div key={game.juego_id} className="grid-item">
              <img src={game.caratula} alt={game.nombre} className="game-image" />
              <div className="game-info">
                <p className='titulo-juego-home' ><h3>{game.nombre_juego}</h3></p>
                <p>{game.nombres_protectores}</p>
                <p>N° de Cartas: {game.num_cartas}</p>
                <button className="view-button" onClick={() => handleViewGame(game.juego_id)}>Ver</button>
              </div>
            </div>
          ))}
        </div>
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
  {selectedGame && (
    <div className="modal-content">
      <img src={selectedGame[0].caratula} alt={selectedGame[0].nombre_juego} className="modal-game-image" />
      <h3>{selectedGame[0].nombre_juego}</h3>
      {selectedGame[0].url && (  // Condición para comprobar si la URL existe
        <button
          className="buy-game-button"
          onClick={() => window.open(selectedGame[0].url, "_blank")}
        >
          <b>Comprar juego</b>
        </button>
      )}
      <table>
        <thead>
          <tr>
            <th>Cartas</th>
            <th>Tamaño</th>
            <th>Protector</th>
            <th>Comprar</th>
          </tr>
        </thead>
        <tbody>
          {selectedGame.map((item, index) => (
            <tr key={index}>
              <td>{item.cantidad}</td>
              <td>{`${item.ancho} x ${item.alto}`}</td>
              <td>
                <a href={item.url_compra} target="_blank" rel="noopener noreferrer">
                  {item.nombre_protector}
                </a>
              </td>
              <td>
                <button className="buy-button" onClick={() => window.open(item.url_compra, "_blank")}>Comprar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <a href={`/reportar-error/${selectedGame[0].juego_id}`} className="report-link">Reportar error</a>
    </div>
  )}
</Modal>

      </div>
      <p>Actualmente tenemos un total de <strong>{totalJuegos} juegos</strong> registrados, y contando!</p>
    </div>
    <Footer />
    </>
  );
}



export default App;
