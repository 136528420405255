// Footer.js
import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';


const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-left">
        <p>Herramienta desarrollada por <a href="https://poresoestoypobre.cl">POR ESO ESTOY POBRE®</a>. Todos los derechos reservados.</p>
      </div>
      <div className="footer-right">
        <a href="https://poresoestoypobre.cl"><FontAwesomeIcon icon={faGlobe} /></a>
        <a href="https://www.facebook.com/PORESOESTOYPOBRE"><FontAwesomeIcon icon={faFacebookF} /></a>
        <a href="https://www.instagram.com/poresoestoypobre"><FontAwesomeIcon icon={faInstagram} /></a>
        <a href="mailto:contacto@poresoestoypobre.cl"><FontAwesomeIcon icon={faEnvelope} /></a>
      </div>
    </footer>
  );
};

export default Footer;

